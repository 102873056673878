import { Poll } from '@vgno/widgets/Poll';
import { render } from 'preact';

export default async (props) => {
    const { hasSession, login, trackEngagement } = props;
    const { pollData } = props.props;
    const user = await hasSession()
        .then((session) => session)
        .catch(() => null);

    props.node?.addEventListener('click', (event) => {
        const closestTarget = event.target.closest(
            'label[data-track-id], button[data-track-id]',
        );
        if (!closestTarget) {
            return;
        }

        trackEngagement({
            type: 'Engagement',
            elementAction: 'Click',
            elementType: 'Poll',
            elementId: closestTarget.dataset.trackId,
            contentId: closestTarget.dataset.trackId,
            elementName: closestTarget.dataset.trackName,
        });
    });

    return render(
        <Poll
            answers={pollData.answers}
            id={pollData.id}
            user={user}
            login={() => login(window.location.href, 'poll', pollData.id)}
            label=""
            question={pollData.question}
            requiresLogin={true}
            subtype={pollData.subtype}
        />,
        props.node,
    );
};
